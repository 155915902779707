import { FoxBizRequest, FoxRestBizClient } from "@/api/client";
import moment from "moment";

/**
 *  관리자 화면 채용 - 모집지원분야/전형절차 관리 기능 클래스
 */
class NoticeOptions {

    /**
     * 생성자
     */
    constructor() {
        
    }

    getNotice(boardCategory) {
        return new Promise((resolve, reject) => {
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.RecruitBiz';
            request.methodId = 'GetNotice';
            request.parameters.boardCategory = boardCategory;

            service.execute(request).then(response => {

                const tables = response.data.result.value;

                if(!!tables) resolve(tables);
                else resolve(null);
            }).catch(error => reject(error));
        })
    }


    /**
     * 모집지원분야 / 전형절차 상세 항목 조회
     * @param {any} seq
     */
    getNoticeOptions(seq) {
        return new Promise((resolve, reject) => {

            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.RecruitBiz';
            request.methodId = 'GetNoticeOptions';
            request.parameters.seq = seq;
            
            service.execute(request).then(response => {

                const tables = response.data.result.value;

                if(!!tables) resolve(tables);
                else resolve(null);
            }).catch(error => reject(error));
        });
    };

    saveNoticeOptions(systCode, seq, part, step) {
        return new Promise((resolve, reject) => {

            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.RecruitBiz';
            request.methodId = 'SaveNoticeOptions';
            request.parameters.systCode = systCode;
            request.parameters.seq = seq;
            request.parameters.part = part;
            request.parameters.step = step;

            service.execute(request).then(response => {
                resolve(response.data)
            }).catch(error => reject(error));
        });
    }

}

export const noticeOptions = new NoticeOptions();