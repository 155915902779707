<template>
    <div class="admin-content">
        <h3>모집지원분야 / 전형절차 관리</h3>
        <div class="flex-wrap right">
            <div class="sch-wrap3">
                <div>
                    <label>채용공고</label>
                    <div class="select-box" style="width:400px;">
                        <select v-model="state.selectedNotice" @change="methods.getInfo()">
                            <option v-for="notice in state.dataTable.recruitNotice" v-bind:value="notice"
                                v-bind:key="notice.Seq" v-text="notice.Title"></option>
                        </select>
                    </div>
                </div>
                <div class="a-tit3 ml20">
                    <label>게시기간</label>
                    <div style="width:5rem;">
                        {{ state.selectedNotice.StartpiredDt }}
                    </div>
                    <span>&ensp;~&ensp;</span>
                    <div style="width:5rem;">
                        {{ state.selectedNotice.ExpiredDt }}
                    </div>
                </div>
                <div class="btn-wrap ml20">
                    <button class="btn-box blue" @click="methods.getInfo()">조회</button>
                    <button class="btn-box gray" @click="methods.saveInfo()">저장</button>
                </div>
            </div>
        </div>

        <div class="flex-wrap half">
            <div class="col-6">
                <!-- 상세 제목 -->
                <div class="table-title-wrap">
                    <p class="a-tit2">모집지원분야</p>
                </div>

                <DataTable class="p-datatable-sm" scrollHeight="497px" :value="state.dataTable.recruitPart"
                    :resizableColumns="true" editMode="row" v-model:editingRows="state.dataTable.rowDataModel">

                    <template #empty>
                        데이터가 없습니다.
                    </template>

                    <Column field="RCRT_DIV_NM" header="분야" headerClass="tc"/>
                    <Column field="USE_YN" header="사용여부" class="tc">
                        <template #body="{ data }">
                            <div class="chk-box">
                                <input type="checkbox" name="recruitUseYN" v-model="data.USE_YN" :binary="true"
                                    @change="eventHandler.changePart(data)" />
                                <label></label>
                            </div>
                        </template>
                    </Column>
                </DataTable>
            </div>

            <div class="col-6">
                <!-- 상세 제목 -->
                <div class="table-title-wrap">
                    <p class="a-tit2">전형절차</p>
                </div>

                <DataTable class="p-datatable-sm" :value="state.dataTable.examStep" :resizableColumns="true">

                    <template #empty>
                        데이터가 없습니다.
                    </template>

                    <Column field="EXAM_DIV_NM" header="전형구분" headerClass="tc"/>
                    <Column field="USE_YN" header="사용여부" class="tc">
                        <template #body="{ data }">
                            <div class="chk-box">
                                <input type="checkbox" name="examUseYN" v-model="data.USE_YN" :binary="true"/>
                                <label></label>
                            </div>
                        </template>
                    </Column>
                    <!-- <Column field="SORT_SEQ" header="정렬순서" :sortable="true" class="tc">
                    <template #body="{ data }">
                        <div>
                            <input type="number" v-model="data.SORT_SEQ" :binary="true" class="input-text mr5" style="text-align:center;" onkeypress='return event.charCode >= 48 && event.charCode <= 57' />
                        </div>
                    </template>
                    </Column> -->
                    <Column field="SORT_SEQ" header="발표시작일" class="tc">
                    <template #body="{ data }">
                        <div>
                            <input type="datetime-local" v-model="data.PREST_ST_DA" :min="state.today" class="input-text mr10" max="9999-12-31T23:59"/>
                        </div>
                    </template>
                    </Column>
                    <Column field="SORT_SEQ" header="발표종료일" class="tc">
                    <template #body="{ data }">
                        <div>
                            <input type="datetime-local" v-model="data.PREST_END_DA" :min="state.today" class="input-text mr10" max="9999-12-31T23:59"/>
                        </div>
                    </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';
import { useStore } from 'vuex';
import { useRoute } from "vue-router";
import { noticeOptions } from '@/modules/recruit/REC_RC0200D.js';
import { announcementManagement } from '@/modules/recruit/REC_RC0500R.js';
import { common } from '@/modules/common/common.js';
import alertManager from '@/utils/alert-confirm-manager';
import router from '@/router';

export default {
    setup() {
        // 스토어
        const store = useStore();

        // 라우트
        const vrt = useRoute();

        // state
        const state = reactive({

            parameter: {

                // 공고 번호
                seq: !!vrt.query.seq ? vrt.query.seq : null

                // 채용공고 카테고리
                , boardCategory: 'RECCM001'
            }

            , dataTable: {

                // 공고
                recruitNotice: new Object()

                // 모집지원분야 목록
                , recruitPart: new Array()

                // 전형절차 실제 저장할 목록 테이블
                , examStep: new Array()

                // 행 데이터 모델
                , rowDataModel: new Array()

                // 유효성 검사 리스트
                , validationList: new Array()
            }

            // 선택된 공고
            , selectedNotice: {
                BoardCategory : ''
                , ExpiredDt : ''
                , Seq : 0
                , StartpiredDt: ''
                , SystCode: ''           
                , Title : ''
            }
            
            // 변경된 모집지원분야
            , changeYNPart: new Array()

            // 오늘날짜
            , today: ''

            // 유효성 검사 통과 여부
            , validationPass: 'Y'
        });

        // 메소드
        const methods = {

            //채용공고 목록 조회
            getNotice: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true);
                
                noticeOptions.getNotice(state.parameter.boardCategory).then(result => {

                    state.dataTable.recruitNotice = result.Table.rows;
                    if (result.Table.rows.length > 0) {
                        state.selectedNotice = state.dataTable.recruitNotice[0]
                    }
                }).finally(() => {

                    methods.getInfo()

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);
                })
            }

            // 모집지원분야 전형절차 조회
            , getInfo: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true);

                // seq로 공고와 연결된 상세 조회
                noticeOptions.getNoticeOptions(state.selectedNotice.Seq.toString()).then(result => {

                    state.dataTable.recruitPart = result.Table.rows;
                    
                    state.dataTable.examStep = result.Table1.rows;

                }).finally(() => {

                    state.dataTable.recruitPart.forEach(row => {
                        row.USE_YN = row.USE_YN === 'Y' ? true : false;
                    })

                    state.dataTable.examStep.forEach(row => {
                        row.USE_YN = row.USE_YN === 'Y' ? true : false;
                    })

                    // 변경값 저장 테이블 초기화
                    state.changeYNPart = new Array();

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);
                })
            }

            // 모집지원분야 전형절차 상세 저장
            , saveInfo: () => {
                
                // 유효성 검사 리스트 초기화
                state.dataTable.validationList = []

                // 유효성 검사 통과 여부 초기화
                state.validationPass = 'Y'

                if (!!state.dataTable.examStep) {
                    state.dataTable.examStep.forEach(row => {
                        if (row.USE_YN === true){
                            state.dataTable.validationList.push(row)
                        } 
                    })

                    // 전형중 하나만 발표시작일/종료일 지정가능
                    let stCnt = 0 , edCnt = 0;
                    state.dataTable.validationList.some((row, index) => {

                        let stDate = row["PREST_ST_DA"];
                        let edDate = row["PREST_END_DA"];
                        
                        if(stDate != '') stCnt ++;
                        if(edDate != '') edCnt ++;

                    })

                    if(stCnt > 1 || edCnt > 1){
                        // 2개이상 들어감
                        alertManager.alert.warning('발표시작일/종료일은 하나의 전형에만 입력해주세요. (2개이상일 경우 삭제하고 진행)'); //멘트 나중에 뭘로할지 수정!!
                        return false;
                    }
                    
                    // 발표시작일 < 발표종료일 유효성 검사
                    state.dataTable.validationList.some(row => {

                        let stDate = new Date(row["PREST_ST_DA"])
                        let edDate = new Date(row["PREST_END_DA"])

                        if(stDate > edDate){

                            alertManager.alert.warning('발표종료일은 발표시작일 이후로 입력해주세요.')
                            state.validationPass = 'N'
                            return true
                        }
                        row["PREST_ST_DA"] = row["PREST_ST_DA"].replace('T', ' ');
                        row["PREST_END_DA"] = row["PREST_END_DA"].replace('T', ' ');
                    })
                    /*
                    // 상위 전형과의 발표시작일 발표종료일 유효성 검사
                    state.dataTable.validationList.some((row, index) => {

                        if(!!state.dataTable.validationList[index + 1]){

                            let stDate = new Date(state.dataTable.validationList[index + 1]["PREST_ST_DA"])
                            let edDate = new Date(row["PREST_END_DA"])

                            if(edDate > stDate){

                                alertManager.alert.warning(state.dataTable.validationList[index + 1]["EXAM_DIV_NM"] + '전형의 발표시작일이 ' + row["EXAM_DIV_NM"] + '전형의 발표종료일보다 작습니다.')
                                state.validationPass = 'N'
                                return true
                            }
                        }
                    })
                    */
                }

                //유효성 검사 통과 시
                if(state.validationPass === 'Y'){
                    
                    // 데이터값 디비에 맞게 변형
                    if (!!state.changeYNPart) {                  
                        state.changeYNPart.forEach(row => {
                            if (row.USE_YN === true) row.USE_YN = 'Y'
                            else row.USE_YN = 'N'
                        })
                    }

                    if (!!state.dataTable.examStep) {
                        state.dataTable.examStep.forEach(row => {
                            if (row.USE_YN === true) row.USE_YN = 'Y'
                            else row.USE_YN = 'N'
                        })
                    }

                    store.commit("setLoadingStatus", true);

                    let changePartJson = JSON.stringify(state.changeYNPart);
                    let changeStepJson = JSON.stringify(state.dataTable.examStep);


                    //해당 절차가 합불발표시작이 되도록 했지만 안내 템플릿이 없을 경우
                    let success = true, check = false;

                    const SaveAndRedirect = () => {
                        
                        //전형 가져와서 안내템플릿 존재 할 경우만 저장이 가능하도록
                        if(success){
    
                            noticeOptions.saveNoticeOptions(state.selectedNotice.SystCode, state.selectedNotice.Seq.toString(), changePartJson, changeStepJson).then((response) => {
        
                                if (response.success) {
                                    alertManager.alert.success('저장되었습니다.')
                                }
                            }).finally(()=>{
                                store.commit("setLoadingStatus", false);
                                methods.getInfo();
                            })
                        }else{
                            alertManager.alert.warning('안내 템플릿이 없습니다. 해당 전형 안내 템플릿 설정 후 다시 저장해주세요.');
                            store.commit("setLoadingStatus", false);
                            methods.getInfo();
                        }
                    }

                    state.dataTable.examStep.forEach(data => {
                        if(data.PREST_ST_DA != "" || data.PREST_END_DA != "") {
                            state.parameter.EXAM_DIV_CD = data.EXAM_DIV_CD;
                            check = true;
                        }
                    })

                    if(check){ // 발표 지정날짜가 있는 경우
                        state.parameter.Seq = state.selectedNotice.Seq.toString();
                        
                        announcementManagement.getAnnounceTemplateDetail(JSON.stringify(state.parameter)).then(result => {
                            
                            if(!!result == false) success = false;
                            else if(result[0]['ANNC_TMPL_CONT'] == "") success = false;

                        }).finally(() => {
                            SaveAndRedirect();   
                        })  
                    }else{
                        SaveAndRedirect();
                    }
                           
                }
            }
        };

        // 이벤트핸들러
        const eventHandler = {

            // 모집지원분야 사용여부(체크박스) 가공
            changePart: (data) => {

                let changePart = {

                    "RCRT_DIV_CD": data.RCRT_DIV_CD,

                    "USE_YN": data.USE_YN
                }

                // 같은 row 중복 체크값 확인
                var checkPart = state.changeYNPart.some(v => v.RCRT_DIV_CD === changePart.RCRT_DIV_CD);

                // 중복 없을 경우
                if (!checkPart) state.changeYNPart.push(changePart)
                // 중복 있을 경우 중복값 제외
                else state.changeYNPart = state.changeYNPart.filter(item => item.RCRT_DIV_CD != data.RCRT_DIV_CD);
            }

            , toDay: () => {

                var date = new Date();
                var year = date.getFullYear();
                var month = ("0" + (1 + date.getMonth())).slice(-2);
                var day = ("0" + date.getDate()).slice(-2);

                state.today = year + "-" + month + "-" + day;
            }
        };

        onMounted(() => {

            // 채용 공고 조회
            methods.getNotice();

            // 오늘날짜 구하기
            eventHandler.toDay();
        });

        return { state, noticeOptions, methods, eventHandler, store, common }
    }
}
</script>